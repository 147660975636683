<template>
  <v-container>
    <v-toolbar color="#16283F" dark flat>
      <v-tabs align="left" fixed-tabs>
        <v-tab left small tile text @click="setstatus(`eventsCalendar`)">Events Calendar </v-tab>
        <v-tab small tile text @click="setstatus(`statistics`)">Statistics </v-tab>
        <v-tab small tile text @click="setstatus(`worklist`)">Worklist </v-tab>
         <v-tab small tile text @click="setstatus(`reports`)">Reports </v-tab>
      </v-tabs>
    </v-toolbar>

    <event class="mx-2" v-if="anniversary && eventsCalendar" :eventlist="anniversary"></event>
    <div v-if="pendingflag && worklist">
      <h4>YOU HAVE {{ hrapporvals.length }} RECORDS TO APPROVE</h4>

      <v-data-table
        :headers="headers"
        :items="hrapporvals"
        :loading="notloaded"
        loading-text="Loading..."
        sort-by="empid"
        class="elevation-1"
        :items-per-page="itemsPerPage"
        :search="search"
        :footer-props="{ prevIcon: 'mdi-arrow-left', nextIcon: 'mdi-arrow-right' }"
      >
        <!--<v-data-table  :headers="headers"   :items="emps"   :expanded.sync="expanded" item-key="officialDetails.empid"  :show-expand="expandflag" class="elevation-1"
        :loading="notloaded" loading-text = "Loading..." :search="search"   :footer-props="{ prevIcon: 'mdi-arrow-left',  nextIcon: 'mdi-arrow-right'  }" 
        @click:row="(item, slot)  =>  slot.expand(!slot.isExpanded)" :items-per-page="roles.itemsPerPage">-->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon v-if="editflag" small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        </template>
        <template v-slot:no-data>
          <div><h2 class="blue">No data Found-appoval</h2></div>
        </template>
      </v-data-table>
    </div>
    <!-- calendar code-->
    <!-- <v-responsive height="700px" width="50vw">
  
 </v-responsive>  end of calendar code-->
    <!--<div v-if="chartloaded" class="chart-container" style="position: relative; height:60vh; width:50vw">-->
    <div v-if="statistics" class="chart-container" style="position: relative">
      <canvas id="emp-chart"></canvas>
    </div>

    <v-simple-table v-if="statistics" dense dark>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left heading-2">Description</th>
            <th class="text-left">Numbers</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in empdata" :key="item.name">
            <td>{{ item.name }}</td>
            <td>{{ item.number }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>
<script>
import { empRef } from "../fb"
//import { PDPRef } from "../fb"
import Chart from "chart.js"
import event from "../components/calendar/events"
import { json2excel } from "js2excel"
// import { convertAll } from "@/mixins/convertall.js"

export default {
  name: "HRadmin",
  // mixins: [convertAll],
  components: {
    event,
  },
  props: ["roles"],

  data: () => ({
    dialog: false,
    menuls: false,
    menuns: false,
    datacollection: null,
    search: "",

    empmenus: [
      { name: "Personal Details", link: "HRdetails" },
      { name: "Document Details", link: "HRdetails" },
      { name: "Emergency Details", link: "HRdetails" },
      { name: "Family Details", link: "HRdetails" },
      { name: "official Details", link: "HRdetails" },
    ],
    //roles:'',
    role: "",
    domain: "",
    empdetailsapproval: false,

    hrapporvals: [],
    allemps:[],
    totalemps: 0,

    resigned: 0,
    pendingrecord: 0,
    pendingflag: false,
    noOfFemaleemp: 0,
    noOfmaleemp: 0,
    Married: 0,
    passportholders: 0,
    totempchildren: 0,

    less1year: 0,
    more1year: 0,
    more2years: 0,
    more3years: 0,
    more4years: 0,
    more5years: 0,
    empdata: [],

    headers: [
      {
        text: "EMPID",
        align: "start",
        sortable: true,
        value: "empid",
      },
      { text: "Email", value: "email" },
      { text: "Name", value: "name" },

      { text: "Actions", value: "actions", sortable: false },
    ],

    todos: [],
    editedIndex: -1,
    editedItem: {
      empid: null,
      email: null,
      name: null,
    },
    defaultItem: {
      empid: null,
      email: null,
      name: null,
    },

    notloaded: true,

    editflag: false,
    empChartData: "",
    anniversary: [],
    eventsCalendar: true,
    statistics: false,
    worklist: false,
    reports:false,
    loading: true,
    itemsPerPage:10,
  }), // end of data

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Activity" : "Edit Activity"
    },
    getuserprofile() {
      return this.$store.state.userProfile
    },
  },

 /* mounted() {
    empRef.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.doc.data().domain == this.domain) {
          console.log("inside mouted",change.type,this.loading)
          if(!this.loading){
            this.anniversary=[];
            this.less1year= 0,
            this.more1year= 0,
           this.more2years= 0,
           this.more3years= 0,
           this.more4years= 0,
           this.more5years= 0,
           this.empdata=[],
          this.queryallrecords();
          }
        } //end of this.domain
      }) // end of for each
    }) // end of snapshot
  },*/ // end of mounted

   
  /*async mounted () {
      this.createChart("emp-chart", this.empChartData);
    },*/
  /* mounted () {
      this.$refs.calendar.checkChange()
    },*/
  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  async created() {
    var tmproles
    if (this.roles) {
      tmproles = this.roles
    } else {
      tmproles = await this.$store.state.userProfile
    }

    console.log("hradmin crated", tmproles)

    if (tmproles) {
      if (tmproles.role.hradmin || tmproles.role.readuser) {
        if (tmproles.role.hradmin) {
          this.editflag = true
        }
        this.domain = tmproles.domain
        this.role = tmproles.role
        this.itemsPerPage = tmproles.itemsPerPage
        await this.queryallrecords()
        this.loading=false
        //this.chartdata();
      } else {
        alert("Not authorized to access this page , kindly contact support")
        this.$router.replace({ name: tmproles.menufrom })
        //this.$router.replace({ name: "/login"});
      }
    } else {
      alert("Not authorized to access this page , kindly contact support")
      this.$router.replace({ name: "/login" })
    }
  },

  methods: {
    setstatus(taboption) {
      this.eventsCalendar = false
      this.statistics = false
      this.worklist = false
      this.reports=false;
      if (taboption == "eventsCalendar") {
        this.eventsCalendar = true
      } else if (taboption == "statistics") {
        ;(this.statistics = true), this.chartdata()
      } else if (taboption == "worklist") {
        this.worklist = true
      } else if (taboption == "reports") {
        this.reports = true;this.reportExport();
      }

      console.log("inside setstatus", taboption, this.eventsCalendar, this.statistics, this.worklist,this.reports)
    },
    async reportExport(){
    console.log("inside reportExport", this.allemps.length)
     var reportsarray=[];
     for(var i=0;i<this.allemps.length;++i){
       var emp=this.allemps[i];
       var officialDetails=emp.officialDetails;
       var personalDetails=emp.personalDetails;
       var familyDetails=emp.familyDetails;
        var documentDetails=emp.documentDetails;
       var emergencyDetails=emp.emergencyDetails;
       await reportsarray.push({ 
          EmpId: officialDetails.empid,
          Email:officialDetails.email,
          Name: officialDetails.name,
          Manager_name: officialDetails.mgrname,
          Manager_Email: officialDetails.mgremail,
          //mgrid: officialDetails.mgrid,
          Cient_manager_Name: officialDetails.clientmgrname,
          Client_manager_Email: officialDetails.clientmgremail,
          //clientmgrid: officialDetails.clientmgrid,
          DOJ: officialDetails.empDOJ,
          DOE: officialDetails.empDOE,
          DOR: officialDetails.empDOR,
          Department: officialDetails.deptName,
          Grade: officialDetails.grade,
          Designation: officialDetails.designation,
          Client: officialDetails.client,
          Onsite: officialDetails.onsite,
          Onsite_Country: officialDetails.onsitecountry,
          
          Personal_Email:personalDetails.email,
          FullName:personalDetails.fullName,
          MobileNumber:personalDetails.mobileNumber,
          Gender:personalDetails.gender,
          DOB:personalDetails.empDOB,
          CurrentAddress:personalDetails.currentAddress,
          AlternateContact:personalDetails.alternateContact,
          PermanentAddress:personalDetails.permanentAddress,
          MatrialStatus:personalDetails.matrialStatus,
          BloodGroup:personalDetails.bloodGroup,
          WeddingAnniversary:personalDetails.weddingAnniversary,
          FatherName:familyDetails.father.name,
          FatherDOB:familyDetails.father.DOfB,
          MotherName:familyDetails.mother.name,
          MotherDOB:familyDetails.mother.DOfB,
          SpouseName:familyDetails.spouse.name,
          SpouseDOB:familyDetails.spouse.DOfB,
          Child1Name:familyDetails.child1.name,
          Child1DOB:familyDetails.child1.DOfB,
          Child1gender:familyDetails.child1.gender,
          Child2Name:familyDetails.child2.name,
          Child2DOB:familyDetails.child2.DOfB,
          Child2gender:familyDetails.child2.gender,
          PassportNumber:documentDetails.passportNumber,
          PassportExpiryDate:documentDetails.passportExpiryDate,
          AddharNumber:documentDetails.addharNumber,
          PanNumber:documentDetails.panNumber,
          PFUAN:documentDetails.pfUAN,
          EmergencycontactName:emergencyDetails.contactName,
          EmergencycontactNumber:emergencyDetails.contactNumber,
          EmergencycontactRelation:emergencyDetails.contactRelation,
          Emergencyalternatecontact:emergencyDetails.alternateContact,




          // doj:officialDetails.empDOJ,
          // Birthday:this.convertDate(emprecords.docs[i].data().personalDetails.empDOB),
          // Wedding:this.convertDate(emprecords.docs[i].data().personalDetails.weddingAnniversary),
          // spouseDOB:this.convertDate(emprecords.docs[i].data().familyDetails.spouse.DOfB),
          // child1DOB:this.convertDate(emprecords.docs[i].data().familyDetails.child1.DOfB),
          // child2DOB:this.convertDate(emprecords.docs[i].data().familyDetails.child2.DOfB),
          // fatherDOB:this.convertDate(emprecords.docs[i].data().familyDetails.father.DOfB),
          // motherDOB:this.convertDate(emprecords.docs[i].data().familyDetails.mother.DOfB),
          // CurrentAddress:emprecords.docs[i].data().personalDetails.currentAddress,
          // PermanentAddress:emprecords.docs[i].data().personalDetails.permanentAddress,

          })

     }
    var data = JSON.parse(JSON.stringify(reportsarray))
     try {
        json2excel({
          data,
          name: "EMPDetails",
          formateDate: "yyyy/mm/dd",
        })
      } catch (e) {
        console.error("export error", e)
      }

    
     

    },

    createChart(chartId, chartData) {
      // console.log("inside createcart",chartId,chartData)

      setTimeout(() => {
        var ctx = document.getElementById(chartId)

        var myChart = new Chart(ctx, {
          type: chartData.type,
          data: chartData.data,
          options: chartData.options,
        })

        if (!myChart) console.log("myChart", myChart)
      }, 100)
    },
    async chartdata() {
      console.log(
        "chartdata",
        this.totalemps,
        this.noOfFemaleemp,
        this.noOfmaleemp,
        this.more5years,
        this.more4years,
        this.more3years,
        this.more2years,
        this.more1year,
        this.less1year
      )
      this.empChartData = {
        type: "bar",
        data: {
          labels: ["Total", "Female", "Male", ">5", "4-5", "3-4", "2-3", "1-2", "0-1", "Resigned"],
          datasets: [
            {
              // one line graph
              label: "Employee Count",
              data: [
                this.totalemps,
                this.noOfFemaleemp,
                this.noOfmaleemp,
                this.more5years,
                this.more4years,
                this.more3years,
                this.more2years,
                this.more1year,
                this.less1year,
                this.resigned,
              ],
              backgroundColor: [
                "#ECEFF1",
                "#82B1FF",
                "#2196F3",
                "#9E9E9E",
                "#B2DBBF",
                "#E9AFA3",
                "#f5f3f4",
                "#FF5252",
                "#1976D2",
                "#247BA0",
              ],
              borderColor: [
                "#36495d",
                "#36495d",
                "#36495d",
                "#36495d",
                "#36495d",
                "#36495d",
                "#36495d",
                "#36495d",
                "#36495d",
                "#36495d",
              ],
              borderWidth: 3,
            },
          ],
        },
        options: {
          responsive: true,
          lineTension: 1,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 10,
                },
              },
            ],
          },
        },
      }
      if (this.statistics) await this.createChart("emp-chart", this.empChartData)
    },

    async queryallrecords() {
      var present_date = new Date()
      var current_year = present_date.getFullYear()
      var next_year = current_year + 1
      var tmpdate
      var expyears = 0;
      var one_year = 1000 * 60 * 60 * 24 * 365
       //var reportsarray=[]; 
      
      //var one_day=1000*60*60*24;
      var emprecords = await empRef.where("domain", "==", this.domain).get() //where("approvalStatus" ,"==", "pending")
      var i = 0
      //this.allemps=  empRef.where("domain", "==", this.domain).get();
      this.totalemps = emprecords.docs.length
      for (i = 0; i < emprecords.docs.length; ++i) {
        this.allemps.push({...emprecords.docs[i].data() })
        if (emprecords.docs[i].data().approvalStatus == "pending") {
          this.pendingrecord++
          this.pendingflag = true
          this.hrapporvals.push({
            uid: emprecords.docs[i].id,
            empid: emprecords.docs[i].data().empid,
            email: emprecords.docs[i].data().email,
            name: emprecords.docs[i].data().name,
            approvalStatus: emprecords.docs[i].data().approvalStatus,
            // role:emprecords.docs[i].data().role,
            mgrname: emprecords.docs[i].data().mgrname,
          })
        }
        //this.totalemps,this.noOfFemaleemp,this.noOfmaleemp,this.more5years,this.more4years,this.more3years,this.more2years,this.more1year,this.less1year
        if (emprecords.docs[i].data().officialDetails.empDOE) {
          this.resigned++
        } else {
          if (emprecords.docs[i].data().personalDetails.gender == "Female") {
            this.noOfFemaleemp++
          }
          if (emprecords.docs[i].data().personalDetails.gender == "Male") {
            this.noOfmaleemp++
          }
          /* if(emprecords.docs[i].officialDetails.currentLocation!="INDIA")
          {
            this.onsiteEmp++;
          }*/
          if (emprecords.docs[i].data().personalDetails.matrialStatus == "Married") {
            this.Married++
          }
          if (emprecords.docs[i].data().documentDetails.passportNumber) {
            this.passportholders++
          }

          if (emprecords.docs[i].data().familyDetails.child1.name) {
            this.totempchildren++
          }
          if (emprecords.docs[i].data().familyDetails.child2.name) {
            this.totempchildren++
          }

          tmpdate = new Date(emprecords.docs[i].data().officialDetails.empDOJ)
          expyears = Math.trunc(Math.abs(tmpdate - present_date) / one_year)
          /* console.log("DOJ",emprecords.docs[i].data().officialDetails.empDOJ);
          console.log("tmpdate",tmpdate,present_date);
          console.log("expyears",expyears);*/

          switch (expyears) {
            case 0:
              this.less1year++
              break
            case 1:
              this.more1year++
              break
            case 2:
              this.more2years++
              break
            case 3:
              this.more3years++
              break
            case 4:
              this.more4years++
              break
            default:
              this.more5years++
          }
         
           this.anniversarypush(
            "Child1 Birthday",
            emprecords.docs[i].data().familyDetails.child1.DOfB,
            current_year,
            "yellow",
            emprecords.docs[i].data()
          )
           this.anniversarypush(
            "Child1 Birthday",
            emprecords.docs[i].data().familyDetails.child1.DOfB,
            next_year,
            "yellow",
            emprecords.docs[i].data()
          )
           this.anniversarypush(
            "Child2 Birthday",
            emprecords.docs[i].data().familyDetails.child2.DOfB,
            current_year,
            "yellow",
            emprecords.docs[i].data()
          )
           this.anniversarypush(
            "Child2 Birthday",
            emprecords.docs[i].data().familyDetails.child2.DOfB,
            next_year,
            "yellow",
            emprecords.docs[i].data()
          )

           this.anniversarypush(
            "Birthday",
            emprecords.docs[i].data().personalDetails.empDOB,
            current_year,
            "green",
            emprecords.docs[i].data()
          )
           this.anniversarypush(
            "Birthday",
            emprecords.docs[i].data().personalDetails.empDOB,
            next_year,
            "green",
            emprecords.docs[i].data()
          )
           this.anniversarypush(
            "Wedding",
            emprecords.docs[i].data().personalDetails.weddingAnniversary,
            next_year,
            "blue",
            emprecords.docs[i].data()
          )
           this.anniversarypush(
            "Wedding",
            emprecords.docs[i].data().personalDetails.weddingAnniversary,
            current_year,
            "blue",
            emprecords.docs[i].data()
          )
           this.anniversarypush(
            "Achievement",
            emprecords.docs[i].data().officialDetails.empDOJ,
            current_year,
            "purple",
            emprecords.docs[i].data()
          )
           this.anniversarypush(
            "Achievement",
            emprecords.docs[i].data().officialDetails.empDOJ,
            next_year,
            "purple",
            emprecords.docs[i].data()
          )
        
          /*await reportsarray.push({ 
          empid: emprecords.docs[i].data().empid,
          empname: emprecords.docs[i].data().name,
          doj:emprecords.docs[i].data().officialDetails.empDOJ,
          Birthday:this.convertDate(emprecords.docs[i].data().personalDetails.empDOB),
          Wedding:this.convertDate(emprecords.docs[i].data().personalDetails.weddingAnniversary),
          spouseDOB:this.convertDate(emprecords.docs[i].data().familyDetails.spouse.DOfB),
          child1DOB:this.convertDate(emprecords.docs[i].data().familyDetails.child1.DOfB),
          child2DOB:this.convertDate(emprecords.docs[i].data().familyDetails.child2.DOfB),
          fatherDOB:this.convertDate(emprecords.docs[i].data().familyDetails.father.DOfB),
          motherDOB:this.convertDate(emprecords.docs[i].data().familyDetails.mother.DOfB),
          CurrentAddress:emprecords.docs[i].data().personalDetails.currentAddress,
          PermanentAddress:emprecords.docs[i].data().personalDetails.permanentAddress,

          })*/
        
       /*   await reportsarray.push({
             EmpID: emprecords.docs[i].data().empid,
             Name: emprecords.docs[i].data().name,
             email:emprecords.docs[i].data().email,
             CurrentAddress:emprecords.docs[i].data().personalDetails.currentAddress,
             PermanentAddress:emprecords.docs[i].data().personalDetails.permanentAddress,
          })*/

        } // end if else empDOE
      
      } //end of for  emprecords.
    
     /* console.log(" covert into json2excel")
      var newpdparray=[];
    
     for(var j=0; j<reportsarray.length; j++){
       var empemail=reportsarray[j].email;
       console.log("empemail",empemail)
       var pdpyear='';
       var nextyearobjectives='';
       if(empemail)
       var doc =  await PDPRef.doc(empemail).get();
       if(doc.exists){
         //console.log("exists",empemail)
           var PDP=[];
           PDP=doc.data().pdp;
           for(var k=0;k<PDP.length;++k){
              var pdpdata=PDP[k];
              if(pdpdata.year=="2020"){
                pdpyear=pdpdata.year;
                nextyearobjectives=pdpdata.nextyearobjectives;
                //console.log("pdpdata.year",pdpyear)
              }
           }//end of pdp data 
       }///end of docexists
       newpdparray.push({
             EmpID: reportsarray[j].EmpID,
             Name: reportsarray[j].Name,
             email:reportsarray[j].email,
             ReviewerName:reportsarray[j].ReviewerName,
             ReviewerEmail:reportsarray[j].ReviewerEmail,
             pdpyear:pdpyear,
             nextyearobjectives:nextyearobjectives
    
       })
     }// end of reportsarray for*/

     // var data = JSON.parse(JSON.stringify(reportsarray))
    /* var data = JSON.parse(JSON.stringify(newpdparray))

      try {
        json2excel({
          data,
          name: "EMPDetails",
          formateDate: "yyyy/mm/dd",
        })
      } catch (e) {
        console.error("export error", e)
      }*/
     
      await this.chartdata()
      /// await this.createChart("emp-chart", this.empChartData);

      this.empdata.push({ name: "Total Employees", number: this.totalemps })
      this.empdata.push({ name: "Male Employees", number: this.noOfmaleemp })
      this.empdata.push({ name: "Female Employees", number: this.noOfFemaleemp })

      this.empdata.push({ name: "Passport Holders", number: this.passportholders })
      this.empdata.push({ name: "More than 5 Years", number: this.more5years })
      this.empdata.push({ name: "4-5 Years", number: this.more4years })

      this.empdata.push({ name: "3-4 Years", number: this.more3years })
      this.empdata.push({ name: "2-3 Years", number: this.more2years })
      this.empdata.push({ name: "1-2 Years", number: this.more1year })
      this.empdata.push({ name: "0-1 Years", number: this.less1year })
      this.notloaded=false
    }, // end of queryallrecords
    anniversarypush(eventname, eventdate, tmpyear, eventcolor, emprec) {
     // console.log("inside anniversarypush")
      var eventmonth = eventdate.substring(5, 7)
      var eventday = eventdate.substring(8, 10)
      var strevent = tmpyear + "-" + eventmonth + "-" + eventday

      if (eventname == "Achievement") {
        var eventyear = eventdate.substring(0, 4)
        var years = tmpyear - eventyear
        var yearcompleted = years + " Year(s) Completed"
      }
      if (eventname == "Child1 Birthday")
        var details = emprec.personalDetails.fullName + " - " + emprec.familyDetails.child1.name
      else if (eventname == "Child2 Birthday")
        details = emprec.personalDetails.fullName + " - " + emprec.familyDetails.child2.name
      else details = emprec.personalDetails.fullName

      var tmpdate = new Date(strevent)
      this.anniversary.push({
        name: eventname,
        start: tmpdate,
        end: tmpdate,
        color: eventcolor,
        empid: emprec.officialDetails.empid,
        details: details,
        experience: yearcompleted,
      })
    }, // end of anniversarypush
    editItem(item) {
      this.editedIndex = this.hrapporvals.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      var tmpempvalues
      tmpempvalues = {
        EmpID: this.editedItem.empid,
        domain: this.domain,
        email: this.editedItem.email,
        name: this.editedItem.name,
        mgrname: this.editedItem.mgrname,
        role: this.role,
      }
      /*  EmpID:newEmail.empid,
             email:newEmail.email,
             status:"active",
             role:"sysadmin",
             name:newEmail.name,
             mgrName:newEmail.mgrName,
             domain:this.domain*/

      console.log("tmpempvalues", tmpempvalues)
      this.$router.push({ name: "employee", params: { roles: tmpempvalues } })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
}
</script>
